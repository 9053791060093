@use "src/app/styles/variables/breakpoints";

.backgroundPage {
  // height: 100vh;
  background: rgb(0 19 54 / 80%);
  // background-image: url("~shared/assets/tokenomics.png");
  // background: url("~shared/assets/tokenomics.png") no-repeat bottom,
  //   linear-gradient(to bottom, rgb(0 19 54 / 80%), darkblue 100%);
  background-size: cover, cover;
  // background-size: cover;
  background-position: center;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

// .backgroundOverlay {
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 350%;
//   background: rgb(0 19 54 / 80%); /* Dark blue color with 50% opacity */
//   z-index: 1; /* Ensure overlay is above the background image */
// }

.content {
  // position: relative;
  margin-top: 80px;
  width: 100%;
  // height: 80%;
  display: flex;
  flex-direction: column;
  //   justify-content: center;
  //   align-items: center;
  z-index: 2; /* Ensure content is above the overlay */
}

.rectangleBox {
  background: rgb(94 109 142 / 38%); /* White color with 50% opacity */
  border-radius: 15px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: center;
  margin: 40px 20px;
  max-width: 1200px;
  // backdrop-filter: blur(10px); /* Apply blur effect */
  // -webkit-backdrop-filter: blur(10px); /* For Safari */
  //   width: 100%;
  // height: 500px;
  min-width: 300px;
  border: 1px solid #2e4481;
  @media (min-width: breakpoints.$lg) {
    margin: 40px 300px;
    flex-direction: row;
    // align-items: center;
  }
}

.monthCard {
  background-color: #051636;
  padding: 15px 30px;
  text-align: left;
  max-width: 250px;
  border-radius: 10px;
  border: 1px solid #2f4273;
}

.monthTitle {
  color: #066df6;
  font-size: 15px;
}

.bulletPoints {
  font-size: 12px;
}

.cardGroup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  // width: 100%;
  gap: 16px;
  margin: 10px;
  // grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
}

.title {
  font-size: 16px;
  font-weight: 700;
  background: linear-gradient(90deg, #32c5ff 0%, #b620e0 50%, #f7b500 100%);
  -webkit-background-clip: text;
  color: transparent;
  font-family: poppins;
  text-align: left;
  max-width: 250px;
  height: 100px;
}
.month {
  color: #066df6;
}
