@use 'app/styles/variables/breakpoints';

$header-height: var(--header-height);
$primary-rgb: var(--primary-rgb);
$primary-light: var(--primary-light);
$primary-invert-rgb: var(--inverted-bg-color);
$header-z-index: var(--header-z-index);
$bg-color: var(--card-bg-color-5);

.Header {
  height: $header-height;
  border-bottom: 1px solid rgba($primary-rgb, 0.3);
  box-shadow: 0 4px 57px -25px $primary-light;
  background-color: rgba($primary-invert-rgb, 0.04);
  backdrop-filter: blur(19.5px);
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: $header-z-index;
}

.contentBox {
  height: inherit;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.in {
  backdrop-filter: blur(19.5px);
  height: inherit;
}

.connectCarnival {
  width: 10.5rem;
  height: 2.5rem;
  overflow: hidden;
  background: linear-gradient(256.63deg, #0537A3 -17.98%, #7E1D99 51.93%, #F7B500 118.41%);
  font-size: 13px;
  font-family: 'Montserrat';
  font-weight: '700';
  border-radius: 200px;
}

.drawer {
  background: url('../../../shared/assets/mobileNavBg.webp') $bg-color bottom;
  background-size: cover;
  background-blend-mode: color-dodge;
}

.hamburger {
  width: 24px;
  height: 24px;
}

.logo {
  @media (width >= breakpoints.$lg) {
    width: 100px;
    height: 41px;
  }
}