$primary-rgb: var(--primary-rgb);
$primary-dark-rgb: var(--primary-dark-rgb);
$primary-light: var(--primary-light);
$card-bg-color-1-rgb: var(--card-bg-color-1-rgb);
$card-bg-color-2-rgb: var(--card-bg-color-2-rgb);
$card-bg-color-3-rgb: var(--card-bg-color-3-rgb);
$z-index: var(--dropdown-z-index);

.Dropdown {
  position: relative;
}

.trigger {
  background: none;
  outline: none;
  margin: 0;
  padding: 0;
  border: none;
  cursor: pointer;
}

.menu {
  background: linear-gradient(
    136.42deg,
    rgb($card-bg-color-1-rgb) 2.72%,
    rgb($card-bg-color-2-rgb) 50.51%,
    rgb($card-bg-color-3-rgb) 98%
  );
  position: absolute;
  z-index: $z-index;
  border-radius: 8px;
  border: 1px solid var(--primary-color);
  overflow: hidden;
  min-width: 100%;
}

.menuIn {
  background: rgba($card-bg-color-1-rgb, 0.04);
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 4px;

  > a {
    hyphens: none;
    word-break: keep-all;
    white-space: nowrap;
  }
}

.item {
  border: none;
  background: none;
  outline: none;
  color: var(--primary-invert);
  cursor: pointer;
  text-align: start;
  overflow-wrap: break-word;
  hyphens: auto;
  text-decoration: none;
  padding: 0.5em 1em;
  width: 100%;
}

.enter {
  transition-duration: 100ms;
  transition-timing-function: ease-out;
}

.enterFrom {
  transform: scale(95%);
  opacity: 0;
}

.enterTo {
  transform: scale(100%);
  opacity: 1;
}

.leave {
  transition-duration: 75ms;
  transition-timing-function: ease-out;
}

.leaveFrom {
  transform: scale(100%);
  opacity: 1;
}

.leaveTo {
  transform: scale(95%);
  opacity: 0;
}

.active {
  background: rgba($card-bg-color-1-rgb, 0.5);
  transition: 0.25s;
}

.triggerOpenAnimation {
  svg {
    transform: rotate(180deg);
    transition: 0.25s;
  }
}
