.fillInherit {
  fill: currentcolor;
}

.strokeInherit {
  stroke: currentcolor;
}

.fillNone {
  fill: none;
}
