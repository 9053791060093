@use 'breakpoints';

:root {
  font-family: Promt, Poppins, Inter, sans-serif;

  @media (min-width: breakpoints.$lg) {
    --font-size-h1: 50px;
    --font-size-h2: 42px;
    --font-size-h3: 39px;
    --font-size-h4: 34px;
    --font-size-h5: 21px;
    --font-size-h6: 20px;
    --font-size-lg: 20px;
    --font-size-md: 18px;
    --font-size-sm: 13px;
    --font-size-middle: 25px;
    --font-size-title: 50px;
    --font-size-subtitle: 21px;
    --chains-card-title: 42px;
    --card-title-1: 35px;

    // additional titles
    --main-title: 110px;
  }

  @media (max-width: breakpoints.$lg) {
    --font-size-h1: 39px;
    --font-size-h2: 24px;
    --font-size-h3: 24px;
    --font-size-h4: 20px;
    --font-size-h5: 18px;
    --font-size-h6: 16px;
    --font-size-lg: 20px;
    --font-size-md: 16px;
    --font-size-sm: 13px;
    --font-size-title: 39px;
    --font-size-subtitle: 14px;
    --chains-card-title: 42px;
    --card-title-1: 24px;
    --font-size-middle: 16px;

    // additional titles
    --main-title: 48px;
  }

  --header-height: 90px;
  --header-z-index: 1100;
  --modal-z-index: 1000;
  --dropdown-z-index: 1200;
}
