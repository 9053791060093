@use 'variables/breakpoints';

@import 'reset';
@import 'theme/dark';
@import 'variables/global';

.app {
  background-color: var(--body-bg);
  min-height: 100vh;
  padding-top: var(--header-height);

  .SafleIDPage {
    background-color: var(--card-bg-color-5);
    overflow: hidden;
  }
}

.container {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 0 16px;
  position: relative;

  @media (max-width: breakpoints.$xs) {
    max-width: 100%;
  }

  @media (min-width: breakpoints.$xs) {
    max-width: 540px;
  }

  @media (min-width: breakpoints.$sm) {
    max-width: 540px;
  }

  @media (min-width: breakpoints.$md) {
    max-width: 720px;
  }

  @media (min-width: breakpoints.$lg) {
    max-width: 960px;
  }

  @media (min-width: breakpoints.$xl) {
    max-width: 1140px;
  }

  @media (min-width: breakpoints.$xxl) {
    max-width: 1420px;
  }
}
