@use "src/app/styles/variables/breakpoints";

.backgroundPage {
  //   height: 100vh;
  background-image: url("~shared/assets/tokenomics.png");
  background-size: cover;
  background-position: center;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.backgroundOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 275%;
  background: rgb(0 19 54 / 80%); /* Dark blue color with 50% opacity */
  z-index: 1; /* Ensure overlay is above the background image */
}

.content {
  position: relative;
  width: 100%;
  height: 80%;
  display: flex;
  margin-top: 80px;
  flex-direction: column;
  //   justify-content: center;
  //   align-items: center;
  z-index: 2; /* Ensure content is above the overlay */
}

.rectangleBox {
  background: rgb(94 109 142 / 38%); /* White color with 50% opacity */
  border-radius: 15px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 40px 100px;
  max-width: 1200px;
  backdrop-filter: blur(10px); /* Apply blur effect */
  -webkit-backdrop-filter: blur(10px); /* For Safari */
  //   width: 100%;
  height: 80%;
  min-width: 500px;
}
