$primary-invert: var(--primary-invert);
$primary-light-rgb: var(--primary-light-rgb);
$primary-light: var(--primary-light);
$primary-dark: var(--primary-dark);
$primary-dark-rgb: var(--primary-dark-rgb);
$primary: var(--primary);
$primary-rgb: var(--primary-rgb);
$secondary: var(--secondary);
$secondary-dark-rgb: var(--secondary-dark-rgb);
$secondary-dark: var(--secondary-dark);
$secondary-invert: var(--secondary-invert);
$secondary-light: var(--secondary-light);
$gradient-accent-1-rgb: var(--gradient-accent-1-rgb);
$gradient-accent-2-rgb: var(--gradient-accent-2-rgb);
$gradient-accent-3-rgb: var(--gradient-accent-3-rgb);
$dark-blue: var(--dark-blue);
$dark-blue-light: var(--dark-blue-light);
$dark-blue-dark-rgb: var(--dark-blue-dark-rgb);
$dark-blue-dark: var(--dark-blue-dark);
$dark-blue-invert: var(--inverted-dark-blue);

// gradient
$gradient: linear-gradient(
  253.18deg,
  rgb($gradient-accent-3-rgb) -11.62%,
  rgb($gradient-accent-1-rgb) 55.05%,
  rgb($gradient-accent-2-rgb) 118.44%
);

// border
$border-width: 0.06em;
$border-radius: 0.4em;
$primary-outline-gradient: linear-gradient(
  90deg,
  rgb($primary-rgb) 0%,
  rgb($primary-dark-rgb) 100%
);

.button {
  cursor: pointer;
  border: none;
  outline: none;
  padding: 0.48em 0.5em;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &:disabled {
    pointer-events: none;
    opacity: 0.4;
    background: $secondary-light;
    border-color: $secondary-light;
    color: $secondary-dark;
  }
}

@each $fontSize in sm, md, lg {
  .#{$fontSize} {
    composes: button;
    font-size: var(--font-size-#{$fontSize});
  }
}

@mixin create-button(
  $color,
  $bg-color-hovered,
  $border-color-hovered: $color,
  $bg-color: transparent,
  $border: true,
  $border-color: $color,
  $color-hovered: $color
) {
  color: $color;
  background-color: $bg-color;
  transition: 0.25s;

  @if $border == true {
    border: $border-width solid $border-color;
  }

  &:hover,
  &:focus {
    background: $bg-color-hovered;

    @if $border == true {
      border-color: $border-color-hovered;
    }
  }
}

@mixin button-gradient($gradient, $radius, $width-of-border) {
  &::before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: $radius;
    padding: $width-of-border;
    background: $gradient;
    mask: $gradient content-box, $gradient;
    mask-composite: exclude;
  }
}

.outline {
  composes: button;

  &--primary {
    @include create-button(
      transparent,
      rgba($primary-light-rgb, 0.075),
      transparent
    );

    @include button-gradient(
      $primary-outline-gradient,
      $border-radius,
      $border-width
    );
  }

  &--secondary {
    @include create-button(
      $secondary-dark,
      rgba($secondary-dark-rgb, 0.75),
      $secondary-dark
    );
  }

  &--gradient {
    @include create-button(
      transparent,
      rgba($primary-light-rgb, 0.075),
      transparent
    );

    @include button-gradient($gradient, $border-radius, $border-width);
  }
}

.contained {
  composes: button;

  &--primary {
    @include create-button(
      $primary-invert,
      $primary-light,
      $primary,
      $primary,
      true,
      $primary,
      $primary-invert
    );
  }

  &--dark-blue {
    @include create-button(
      $dark-blue-invert,
      $dark-blue-light,
      $dark-blue-invert,
      $dark-blue,
      true,
      $dark-blue-invert,
      $dark-blue-invert
    );
  }
}

.noBorder {
  border: none;

  &::before {
    content: '';
    padding: 0;
    background: none;
    mask: unset;
    mask-composite: unset;
  }
}

.rounded {
  border-radius: $border-radius;

  &::before {
    content: '';
    border-radius: $border-radius;
  }
}

.roundedPill {
  border-radius: 200px;

  &::before {
    content: '';
    border-radius: 200px;
  }
}

.square {
  border-radius: unset;

  &::before {
    content: '';
    border-radius: unset;
  }
}

.fullWidth {
  width: 100%;
}

.clear {
  composes: button;
  padding: 0;

  &--primary {
    @include create-button(
      $primary,
      transparent,
      $primary-dark,
      transparent,
      false
    );
  }

  &--secondary {
    @include create-button(
      $secondary-dark,
      transparent,
      $secondary-dark,
      transparent,
      false
    );
  }
}
