@use "src/app/styles/variables/breakpoints";

/* Common styles */
.banner {
  width: 100%;
  padding: 10px 30px;
  background: linear-gradient(90deg, #32c5ff 0%, #b620e0 50%, #f7b500 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  position: relative;
  z-index: -1;
}

.banner-title {
  display: flex;
  align-items: center;
  font-size: 17px;
  color: white;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}

.banner-content {
  display: flex;
  align-items: center;
  font-size: 13px;
  color: white;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}

.banner-content a {
  color: white;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.divider {
  width: 1px;
  height: 20px;
  background-color: white;
}

.links-row {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

/* Mobile styles */
@media (max-width: 768px) {
  .banner {
    flex-direction: column;
    align-items: center;
  }

  .links-row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 10px;
    width: 100%;
  }

  .banner-content {
    justify-content: center;
  }

  .links-row .banner-content:nth-child(3n) + .divider {
    display: none;
  }

  .divider {
    display: none;
  }
}
