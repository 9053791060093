$text-danger: var(--text-danger);
$text-danger-interaction: var(--text-danger-interaction);
$text-primary: var(--text-primary);
$text-subtitle1: var(--text-primary-subtitle1);
$text-primary-interaction: var(--text-primary-interaction);
$text-secondary: var(--text-secondary);
$text-secondary-interaction: var(--text-secondary-interaction);
$text-dark: var(--text-dark);
$text-dark-interaction: var(--text-dark-interaction);
$font-size-h6: var(--font-size-h6);
$font-size-h5: var(--font-size-h5);
$font-size-h4: var(--font-size-h4);
$font-size-h3: var(--font-size-h3);
$font-size-h2: var(--font-size-h2);
$font-size-h1: var(--font-size-h1);
$font-size-md: var(--font-size-md);
$font-size-sm: var(--font-size-sm);
$font-size-middle: var(--font-size-middle);
$main-title: var(--main-title);
$font-size-title: var(--font-size-title);
$font-size-subtitle: var(--font-size-subtitle);
$gradient-accent-1: var(--gradient-accent-1);
$gradient-accent-2: var(--gradient-accent-2);
$gradient-accent-3: var(--gradient-accent-3);

@mixin all-interactions {
  &:hover,
  &:focus,
  &:active {
    @content;
  }
}

@mixin text-palette($name, $color, $interaction-color) {
  .#{$name} {
    color: $color;

    &-hover {
      &:hover {
        color: $interaction-color;
      }
    }

    &-focus {
      &:focus {
        color: $interaction-color;
      }
    }

    &-active {
      &:active {
        color: $interaction-color;
      }
    }

    &-all {
      @include all-interactions {
        color: $interaction-color;
      }
    }
  }
}

@mixin text-palette-gradient($name, $background, $interaction-background) {
  .#{$name} {
    background: $background;
    background-clip: text;
    -webkit-text-fill-color: transparent;

    &-hover {
      &:hover {
        background: $interaction-background;
      }
    }

    &-focus {
      &:focus {
        background: $interaction-background;
      }
    }

    &-active {
      &:active {
        background: $interaction-background;
      }
    }

    &-all {
      @include all-interactions {
        background: $interaction-background;
      }
    }
  }
}

.p {
  font-size: $font-size-md;
}

.middle {
  font-size: $font-size-middle;
  line-height: 44px;
}

.small {
  font-size: $font-size-sm;
}

.title {
  font-size: $font-size-title;
  font-weight: 600;
}

.subtitle1 {
  font-size: $font-size-subtitle;
  color: $text-subtitle1;
  font-weight: 300;
}

.span {
  font-size: inherit;
}

.thin {
  font-weight: lighter;
}

.bold {
  font-weight: bold;
}

.medium {
  font-weight: 500;
}

.semibold {
  font-weight: 600;
}

.normal {
  font-weight: normal;
}

.italic {
  font-style: italic;
}

.poppins {
  font-family: Poppins, sans-serif;
}

.inter {
  font-family: Inter, sans-serif;
}

.prompt {
  font-family: Prompt, sans-serif;
}

.fontInherit {
  font-family: inherit;
}

.fontDefault {
  font-family: sans-serif;
}

.noDecoration {
  text-decoration: none;
}

.underline {
  text-decoration: underline;
}

.transition {
  transition: 0.25s;

  &:focus {
    transition: none;
  }
}

@include text-palette(inherit, inherit, inherit);
@include text-palette(danger, $text-danger, $text-danger-interaction);
@include text-palette(primary, $text-primary, $text-primary-interaction);
@include text-palette(secondary, $text-secondary, $text-secondary-interaction);
@include text-palette(subtitle1, $text-subtitle1, $text-subtitle1);
@include text-palette(dark, $text-dark, $text-dark-interaction);
@include text-palette-gradient(
  gradientRed,
  linear-gradient(
    253.18deg,
    $gradient-accent-3 -11.62%,
    $gradient-accent-1 55.05%,
    $gradient-accent-2 118.44%
  ),
  linear-gradient(
    0deg,
    $gradient-accent-3 -11.62%,
    $gradient-accent-1 55.05%,
    $gradient-accent-2 118.44%
  )
);

@include text-palette-gradient(
  gradientPurple,
  linear-gradient(
    0deg,
    $gradient-accent-3 -11.62%,
    $gradient-accent-1 55.05%,
    $gradient-accent-2 118.44%
  ),
  linear-gradient(
    0deg,
    $gradient-accent-3 -11.62%,
    $gradient-accent-1 55.05%,
    $gradient-accent-2 118.44%
  )
);

@for $i from 1 through 6 {
  .h#{$i} {
    font-size: var(--font-size-h#{$i});
  }
}

.start {
  text-align: start;
}

.center {
  text-align: center;
}

.end {
  text-align: end;
}

.inheritSize {
  font-size: inherit;
}

.mainTitle {
  font-size: $main-title;
}
