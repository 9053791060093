@use "src/app/styles/variables/breakpoints";

.PrivacyPolicyContainer {
  padding: 40px 80px;

  h3 {
    color: #fff;
  }

  .ol {
    padding-left: 40px;
    color: #fff;
    font-size: 20px;
  }
}
