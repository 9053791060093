.loaderBox {
  backdrop-filter: blur(84.5px);
  min-height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loaderBase {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  animation: fade-in-out-keyframes 1.8s infinite ease-in-out;
  animation-fill-mode: both;
}

.loader {
  composes: loaderBase;
  color: #fff;
  font-size: 7px;
  position: relative;
  text-indent: -9999em;
  transform: translateZ(0);
  animation-delay: -0.16s;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 0;
    border-radius: 50%;
    width: 2.5em;
    height: 2.5em;
    animation: fade-in-out-keyframes 1.8s infinite ease-in-out;
    animation-fill-mode: both;
  }

  &::before {
    left: -3.5em;
    animation-delay: -0.32s;
  }

  &::after {
    left: 3.5em;
  }
}

@keyframes fade-in-out-keyframes {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em;
  }

  40% {
    box-shadow: 0 2.5em 0 0;
  }
}
