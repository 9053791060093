$bg-color: var(--card-bg-color-5);
$primary: var(--primary);
$header-height: var(--header-height);

.MobileNav {
  height: calc(100vh - $header-height);
}

.navList {
  height: inherit;
  padding: 70px 10px 10px;
  list-style: none;
}

.navItem {
  padding: 18px 0;
  border-bottom: 1px solid $primary;
  display: flex;
  align-items: center;
  justify-content: space-between;

  > svg {
    fill: $primary;
  }
}

.connectWallet {
  width: 100%;
  margin-top: 43px;
}

.connectCarnival{
  width: 100%;
  margin-top: 43px;
}

.nextArrow {
  transform: rotate(270deg);
}

.backArrow {
  transform: rotate(90deg);
  margin-right: 28px;
}

.navLink {
  width: 100%;
}

.backNavItem {
  composes: navItem;
  justify-content: flex-start;
}

.drawer {
  background: url('../../../../shared/assets/mobileNavBg.webp') $bg-color bottom;
  background-size: cover;
  background-blend-mode: color-dodge;
}
