$header-height: var(--header-height);
$modal-z-index: var(--modal-z-index);

.Drawer {
  position: fixed;
  inset: 0;
  opacity: 0;
  pointer-events: none;
  z-index: -1;
  display: flex;
  align-items: flex-end;
}

.content {
  height: calc(100vh - $header-height);
  top: 0;
  position: relative;
  width: 100%;
  min-height: 100px;
  transition: 0.3s transform;
  transform: translateY(-100%);
  overflow-y: auto;
  overflow-x: hidden;
  z-index: $modal-z-index;
}

.opened {
  pointer-events: auto;
  opacity: 1;
  z-index: $modal-z-index;

  .content {
    transform: translateY(0);
  }
}
